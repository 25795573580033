
  import {
    defineComponent
  } from "vue";
  import { useRouter } from "vue-router";
  export default defineComponent({
    name: "StrAppraisal",
    setup() {
      const router = useRouter();
      function onBack() {
        router.back()
      }
      return {
        onBack
      };
    },
  });
